import en from "./en/translation.json"; // english
import en_mop from "./en/mop.translation.json"; // english
import en_oui from "./en/oui.translation.json";
import en_jackwolfskin from "./en/jackwolfskin.translation.json";
import en_schoeffel from "./en/schoeffel.translation.json";
import en_bogner from "./en/bogner.translation.json";

import de from "./de/translation.json"; //deutch
import de_formal from "./de/formal.translation.json"; // deutch
import de_mop from "./de/mop.translation.json"; //deutch
import de_oui from "./de/oui.translation.json";
import de_jackwolfskin from "./de/jackwolfskin.translation.json";
import de_schoeffel from "./de/schoeffel.translation.json";
import de_tchibo from "./de/tchibo.translation.json";
import de_bogner from "./de/bogner.translation.json";
import de_drykorn from "./de/drykorn.translation.json";
import de_so from "./de/so.translation.json";
import de_eterna from "./de/eterna.translation.json";

import pl from "./pl/translation.json"; //polish
import pl_mop from "./pl/mop.translation.json"; //polish
import pl_jackwolfskin from "./pl/jackwolfskin.translation.json";
import pl_schoeffel from "./pl/schoeffel.translation.json";

import nl from "./nl/translation.json"; //dutch
import nl_mop from "./nl/mop.translation.json"; //dutch
import nl_jackwolfskin from "./nl/jackwolfskin.translation.json";
import nl_schoeffel from "./nl/schoeffel.translation.json";
import nl_bogner from "./nl/bogner.translation.json";

import fr from "./fr/translation.json"; //french
import fr_mop from "./fr/mop.translation.json"; // french
import fr_jackwolfskin from "./fr/jackwolfskin.translation.json";
import fr_schoeffel from "./fr/schoeffel.translation.json";
import fr_bogner from "./fr/bogner.translation.json";

import cz from "./cz/translation.json"; //czech
import cz_mop from "./cz/mop.translation.json"; //czech

import it from "./it/translation.json"; //italian
import it_mop from "./it/mop.translation.json"; //italian
import it_jackwolfskin from "./it/jackwolfskin.translation.json";
import it_schoeffel from "./it/schoeffel.translation.json";

import es from "./es/translation.json"; //spanish
import es_mop from "./es/mop.translation.json"; //spanish
import es_jackwolfskin from "./es/jackwolfskin.translation.json";
import es_schoeffel from "./es/schoeffel.translation.json";

import hr from "./hr/translation.json"; //Croation

import sk from "./sk/translation.json"; //Slovak

import sl from "./sl/translation.json"; //Slovanian

import { BrandConstants } from "../models";

export interface ILangauge {
  en: any;
  de: any;
  fr: any;
  nl: any;
  pl: any;
  cz: any;
  it: any;
  es: any;
  hr: any;
  sk: any;
  sl: any;
}

const defaultLanguage = {
  en: en,
  de: de,
  fr: fr,
  nl: nl,
  pl: pl,
  cz: cz,
  it: it,
  es: es,
  hr: hr,
  sk: sk,
  sl: sl,
} as ILangauge;

export const langToggle = (brandCode: string) => {
  switch (brandCode) {
    case BrandConstants.BONITA:
    case BrandConstants.TRIGEMA:
    case BrandConstants.COMMA:
      return { ...defaultLanguage, de: de_formal } as ILangauge;
    case BrandConstants.MARCOPOLO:
      return {
        en: en_mop,
        de: de_mop,
        fr: fr_mop,
        nl: nl_mop,
        pl: pl_mop,
        cz: cz_mop,
        es: es_mop,
        it: it_mop,
      } as ILangauge;
    case BrandConstants.JACKWOLFSKIN:
      return {
        ...defaultLanguage,
        en: en_jackwolfskin,
        de: de_jackwolfskin,
        fr: fr_jackwolfskin,
        nl: nl_jackwolfskin,
        pl: pl_jackwolfskin,
        es: es_jackwolfskin,
        it: it_jackwolfskin,
      } as ILangauge;
    case BrandConstants.SCHOEFFEL:
      return {
        ...defaultLanguage,
        en: en_schoeffel,
        de: de_schoeffel,
        fr: fr_schoeffel,
        nl: nl_schoeffel,
        pl: pl_schoeffel,
        es: es_schoeffel,
        it: it_schoeffel,
      } as ILangauge;
    case BrandConstants.BOGNER:
    case BrandConstants.FIREICE:
      return {
        ...defaultLanguage,
        en: en_bogner,
        de: de_bogner,
        nl: nl_bogner,
        fr: fr_bogner,
      };
    case BrandConstants.DRYKORN:
      return {
        ...defaultLanguage,
        de: de_drykorn,
      };
    case BrandConstants.SOLIVER:
      return {
        ...defaultLanguage,
        de: de_so,
      };
    case BrandConstants.ETERNA:
      return {
        ...defaultLanguage,
        de: de_eterna,
      };
    case BrandConstants.OUI:
      return { ...defaultLanguage, de: de_oui, en: en_oui } as ILangauge;
    case BrandConstants.TCHIBO:
      return { ...defaultLanguage, de: de_tchibo } as ILangauge;
    default:
      return defaultLanguage;
  }
};
