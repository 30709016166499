import { useEffect, useState, useCallback } from "react";

const useSpinnerObserver = (handleAttributeChange: () => void) => {
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);

  const checkForElement = useCallback(() => {
    const targetElement = document.querySelector(
      ".spinner.spinner--page.is-active"
    );

    if (targetElement && !isSpinnerVisible) {
      setIsSpinnerVisible(true);
      handleAttributeChange();
    } else if (!targetElement && isSpinnerVisible) {
      setIsSpinnerVisible(false);
    }
  }, [isSpinnerVisible, handleAttributeChange]);

  useEffect(() => {
    checkForElement();

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          checkForElement();
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [checkForElement]);

  return null;
};

export default useSpinnerObserver;
