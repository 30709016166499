import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { langToggle } from "./translations";
const resources = (brandCode: string) => {
  const lang = langToggle(brandCode);
  return {
    en: {
      common: lang.en, // 'common' is our custom namespace
    },
    de: {
      common: lang.de,
    },
    nl: {
      common: lang.nl, // 'common' is our custom namespace
    },
    pl: {
      common: lang.pl,
    },
    fr: {
      common: lang.fr,
    },
    cz: {
      common: lang.cz,
    },
    cs: {
      common: lang.cz,
    },
    es: {
      common: lang.es,
    },
    it: {
      common: lang.it,
    },
    sl: {
      common: lang.sl,
    },
    hr: {
      common: lang.hr,
    },
    sk: {
      common: lang.sk,
    },
  };
};
const II8n = (brandCode: string) =>
  i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: ["de"],
      debug: false,
      supportedLngs: [
        "en",
        "de",
        "nl",
        "pl",
        "de_DE",
        "it",
        "es",
        "cz",
        "cs",
        "fr",
        "sl",
        "sk",
        "hr",
      ],
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      resources: resources(brandCode),
      ns: [brandCode],
    });
export default II8n;
