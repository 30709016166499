import { thunk } from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers, configureStore } from '@reduxjs/toolkit';
// Logger with default options
import {
  AuthReducer,
  AlertReducer,
  ProductReducer,
  CalculatorReducer,
  CookieReducer,
  SessionReducer,
  authState
} from '../reducers';
// Import the transformer creator
import expireReducer from 'redux-persist-expire';
import { v4 as uuid } from "uuid";

const rootPersistConfig = {
  key: `saiz-widget`,
  storage: storage,
  // blacklist: ['SessionReducer'],
  transforms: [
    // Create a transformer by passing the reducer key and configuration. Values
    // shown below are the available configurations with default values
    expireReducer('AuthReducer', {
      // (Optional) Key to be used for the time relative to which store is to be expired
      persistedAtKey: 'expirationDate',
      // (Required) Seconds after which store will be expired
      expireSeconds: (7776000), //(90) days
      // (Optional) State to be used for resetting e.g. provide initial reducer state
      expiredState: { ...authState },
      // (Optional) Use it if you don't want to manually set the time in the reducer i.e. at `persistedAtKey` 
      // and want the store to  be automatically expired if the record is not updated in the `expireSeconds` time
      autoExpire: false
    }),
    expireReducer('SessionReducer', {
      // (Optional) Key to be used for the time relative to which store is to be expired
      persistedAtKey: 'sessionCreated',
      // (Required) Seconds after which store will be expired
      expireSeconds: (1200), //(20 mins) days
      // (Optional) State to be used for resetting e.g. provide initial reducer state
      expiredState: { sessionId: uuid(), sessionCreated: (new Date()).toJSON(), isLoaded: true },
      // (Optional) Use it if you don't want to manually set the time in the reducer i.e. at `persistedAtKey` 
      // and want the store to  be automatically expired if the record is not updated in the `expireSeconds` time
      autoExpire: false
    })
  ],
}
const rootReducer = combineReducers<any>({
  SessionReducer,// persistReducer(sessionPersistConfig, SessionReducer),
  AuthReducer,
  AlertReducer,
  ProductReducer,
  CalculatorReducer,
  CookieReducer,
})

const persistorReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = configureStore({
  reducer: persistorReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable the serializable check for redux-persist
    }).concat(thunk),
  devTools: process.env.NODE_ENV !== 'production',
})

export const persistor = persistStore(store)
