import React, { useEffect, useState } from "react";
import { BrandConstants } from "../models";
import { createPortal } from "react-dom";
import { findBrandConfigByNameAndElementType } from "../utils/utils";
import { ElementType } from "../utils/config";
import { connect } from "react-redux";

import {
  BognerLinkContent,
  CommonLinkContent,
  DryKornLinkContent,
  MopLinkContent,
  SOLinkContent,
  JwLinkContent,
} from "./brandLinks";

const SaizLink = (props: any) => {
  const [target, setTarget] = useState<HTMLDivElement | null>(null);
  const brandConfig = findBrandConfigByNameAndElementType(
    props.brandCode,
    ElementType.SaizButton
  );
  useEffect(() => {
    if (!brandConfig) return;
    const targetEl = document.querySelector(brandConfig.targetQuerySelector);
    if (targetEl) {
      setTarget(targetEl as HTMLDivElement);
    }
  }, [props.productCode, props.lang]);

  const getLink = () => {
    switch (props.brandCode) {
      case BrandConstants.MARCOPOLO:
        return <MopLinkContent {...props} target={target} />;
      case BrandConstants.SOLIVER:
      case BrandConstants.COMMA:
      case BrandConstants.QSOLIVER:
        return <SOLinkContent {...props} target={target} />;
      case BrandConstants.BOGNER:
      case BrandConstants.FIREICE:
        return <BognerLinkContent {...props} target={target} />;
      case BrandConstants.DRYKORN:
        return <DryKornLinkContent {...props} target={target} />;
      case BrandConstants.JACKWOLFSKIN:
        return <JwLinkContent {...props} target={target} />;
      default:
        return <CommonLinkContent {...props} target={target} />;
    }
  };
  if (target) {
    target.style.position = "relative";
    return createPortal(getLink(), target);
  }
  return getLink();
};
const mapStateToProps = (state: any) => {
  return {
    product: state.ProductReducer,
    auth: state.AuthReducer,
    cal: state.CalculatorReducer,
    cookie: state.CookieReducer,
    session: state.SessionReducer,
  };
};

export default connect(mapStateToProps)(SaizLink);
