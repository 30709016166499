import { BrandConstants, SaizWidgetParams } from "../models";
import { BonitaRoot } from "../models/bonita";
import { LanguageTypeDto } from "../swagger";
import { Config } from "./config";

export const cmToInch = (value: number | undefined) =>
  Math.round(value ? value * 0.393701 : 0);
export const inchToCm = (value: number | undefined) =>
  Math.round(value ? value / 0.393701 : 0);

export const kgToPound = (value: number | undefined) =>
  Math.round(value ? value * 2.20462 : 0);
export const poundTokg = (value: number | undefined) =>
  Math.round(value ? value / 2.20462 : 0);

export function findBrandConfigByNameAndElementType(
  brandCode: string,
  elementToAppend: string
) {
  try {
    const result = Config[brandCode.toUpperCase()];
    if (result) {
      return result[elementToAppend];
    }
    return null;
  } catch (err) {
    console.error(err);
  }
  return null;
}

export const readCookie = (brandCode: string) => {
  const json = localStorage.getItem("uc_settings") ?? "{}";
  const initialValue = JSON.parse(json) as BonitaRoot;
  localStorage.setItem("saiz_ga", "true");
  let isActive = true;
  switch (brandCode) {
    case BrandConstants.BONITA:
      if (initialValue?.services?.length > 5)
        isActive = initialValue?.services[6]?.status;
      break;
    case BrandConstants.TOMTAILOR:
      if (initialValue?.services?.length > 32) {
        isActive =
          initialValue?.services?.find((val) => val.id === "yPjJ1Z33ZxXdZS")
            ?.status === true;
        localStorage.setItem(
          "saiz_ga",
          String(
            initialValue?.services?.find((val) => val.id === "87JYasXPF")
              ?.status
          )
        );
        break;
      } else isActive = false;
      break;
    case BrandConstants.JACKWOLFSKIN:
      if (initialValue?.services?.length > 17) {
        isActive =
          initialValue?.services?.find((val) => val.id === "o2BmSI7Mo68wOB")
            ?.status === true;
        localStorage.setItem(
          "saiz_ga",
          String(
            initialValue?.services?.find((val) => val.id === "HkocEodjb7")
              ?.status
          )
        );
        break;
      } else isActive = false;
      break;
  }
  localStorage.setItem(
    "saiz-widget-cookie-flag",
    isActive ? "active" : process.env.REACT_APP_COOKIE_FLAG ?? "inactive"
  );
  window.dispatchEvent(new Event("saiz-widget-cookie"));
  return isActive;
};

export const renderWidgetParamsFromContainerId = (id: string) => {
  const container = document.getElementById(id) as HTMLDivElement;
  return renderWidgetParamsFromContainer(container);
};

export const renderWidgetParamsFromContainer = (container: any) => {
  const brandCode: string = container?.getAttribute("data-brandcode") ?? "";
  const productCode: string = container?.getAttribute("data-productcode") ?? "";
  const lang: string = container?.getAttribute("data-lang") ?? "de";
  const visitorId: string =
    container?.getAttribute("data-visitorid") ??
    container?.getAttribute("data-size-finder-variant-id") ??
    "none";
  return {
    brandCode: brandCode?.replace("+","").toUpperCase(),
    productCode: productCode,
    lang: lang?.toLowerCase(),
    visitorId: visitorId,
  } as SaizWidgetParams;
};

export const IsValidUrl = (code: string) =>
  window.location.href?.includes(code);

export function shadowQuery(
  selector: string,
  rootNode: Document | Element = document
): Element | null {
  const selectors = String(selector).split(">>>");
  let currentNode = rootNode;

  selectors.find((selector, index) => {
    if (index === 0) {
      currentNode = rootNode.querySelector(selectors[index]) as Element;
    } else if (currentNode instanceof Element) {
      currentNode = currentNode?.shadowRoot?.querySelector(
        selectors[index]
      ) as Element;
    }

    return currentNode === null;
  });

  if (currentNode === rootNode) {
    return null;
  }

  return currentNode as Element | null;
}

const getShadowRoot = (elem: any, selector: any) =>
  elem?.shadowRoot?.querySelector(selector);

export const getProductCode = (brandCode: string, productCode: string) => {
  switch (brandCode?.toLowerCase()) {
    case BrandConstants.OUI:
      return productCode?.includes("-")
        ? productCode.split("-")[0]
        : productCode;
    case BrandConstants.MARCOPOLO:
      return productCode?.includes("_")
        ? productCode.split("_")[0]
        : productCode;
    case BrandConstants.TOMTAILOR:
      productCode = productCode.replace(" ", "_");
      return productCode?.includes("_")
        ? productCode.split("_")[0]
        : productCode;
    default:
      return productCode;
  }
};

export const getNudgeLanguageType = (lang: string) => {
  switch (lang) {
    case "cz":
      return LanguageTypeDto.CZ;
    case "en":
      return LanguageTypeDto.EN;
    case "es":
      return LanguageTypeDto.ES;
    case "fr":
      return LanguageTypeDto.FR;
    case "it":
      return LanguageTypeDto.IT;
    case "nl":
      return LanguageTypeDto.NL;
    case "pl":
      return LanguageTypeDto.PL;
    case "de":
    default:
      return LanguageTypeDto.DE;
  }
};
