import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
} from "@chakra-ui/react";

import SaizImageLoader from "./saizImageLoader";
import { BrandConstants } from "../models";

interface SaizModalProps {
  onClose: () => void;
  showModal: boolean;
  children?: React.ReactNode;
  isCentered?: boolean;
  brandCode?: string;
}

const SaizModal = (props: SaizModalProps) => {
  const { showModal, onClose, isCentered, children, brandCode } = props;
  return (
    <Modal
      isCentered={isCentered}
      isOpen={showModal}
      onClose={onClose}
      blockScrollOnMount={false}
      size={"md"}
      id="saiz-modal-container"
    >
      <ModalOverlay
        bg="none"
        backdropFilter="auto"
        backdropInvert="30%"
        backdropBlur="1px"
      />
      <ModalContent
        className="saiz-encapculate"
        borderRadius={{ base: 0, md: "20px" }}
        margin={{ base: 0, md: "auto" }}
        backgroundColor="white"
        width={{
          base: "100%",
          md: "900px",
        }}
        maxWidth={{
          base: "98%",
          md: "900px",
        }}
        height={{
          base: "100%",
          md: "700px",
        }}
        minHeight={{
          base: "100%",
          md: "700px",
        }}
        sx={{
          overflow: "auto",
          overscrollBehaviorY: "none",
        }}
      >
        {brandCode !== BrandConstants.BOGNER &&
          brandCode !== BrandConstants.FIREICE && (
            <ModalHeader className="saiz-modal-header">
              <Flex
                align="center"
                justify="center"
                ref={(node: any) => {
                  node?.style.setProperty("display", "flex", "important");
                }}
              >
                <span
                  onClick={() => {
                    window.open("https://www.saiz.io/", "_blank");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <SaizImageLoader
                    item="saiz.png"
                    style={{
                      width: "49px",
                    }}
                    alt="saiz"
                  />
                </span>
              </Flex>
            </ModalHeader>
          )}

        <ModalCloseButton
          size="xl"
          mr="20px"
          mt="24px"
          ref={(node) => {
            node?.style.setProperty("margin-right", "20px", "important");
            node?.style.setProperty("margin-top", "24px", "important");
          }}
          _hover={{ bg: "transparent" }}
          className="saiz-modal-close-button"
          color="#001931"
        />
        <ModalBody
          h="180px"
          px={{
            base: "10px",
            md: "24px",
          }}
        >
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default SaizModal;
