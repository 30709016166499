import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ModalContext } from "../../context/modalContext";
import { ScreenTypeDto } from "../../models";

export const MopLinkContent = (props: any) => {
  const {
    cal,
    product,
    getMeasurements,
    setStep,
    setReturnToScreen,
    productCode,
    saizEvents,
  } = props;
  const { setModal } = useContext(ModalContext);
  const { t } = useTranslation("common");
  const handleClick = () => {
    if (cal?.data?.data?.measuredSize) {
      if (!cal?.isLoaded) {
        getMeasurements();
      }
      setStep(ScreenTypeDto.Summary);
      setReturnToScreen(ScreenTypeDto.Summary);
      saizEvents(ScreenTypeDto.Summary);
    } else {
      setStep(ScreenTypeDto.ScreenOne);
      setReturnToScreen(ScreenTypeDto.ScreenOne);
      saizEvents(ScreenTypeDto.ScreenOne);
    }
    setModal(true);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      handleClick();
    }
  };
  return (
    <div
      role="button"
      style={{ width: "100%" }}
      onClick={handleClick}
      id="saiz-floating-button"
      saiz-widget-flag={
        product?.isLoaded && product?.product?.isActive ? "true" : "false"
      }
      saiz-widget-productcode={productCode}
      saiz-widget-nosize={cal?.isLoaded && cal?.data?.noSize ? "true" : "false"}
      saiz-widget-recommendation={
        !cal?.isLoaded || cal?.data?.noSize
          ? ``
          : `${cal?.data?.data?.measuredSize}`
      }
    ></div>
  );
};
