import React, { useContext, useEffect, useCallback } from "react";
import { ModalContext } from "../../context/modalContext";
import { ScreenTypeDto } from "../../models";
import { useTranslation } from "react-i18next";

export const JwLinkContent = (props: any) => {
  const {
    cal,
    getMeasurements,
    setStep,
    setReturnToScreen,
    saizEvents,
    product,
    lang,
    productCode,
  } = props;
  const { setModal } = useContext(ModalContext);
  const { t, i18n } = useTranslation("common");
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);
  useEffect(() => {
    if (product?.product?.isActive) {
      updateContent();
    } else {
      const recommenderLink = document.getElementById("jwsaizlink");
      const seperator = document.getElementById("jwsaizseperator");
      if (recommenderLink) {
        recommenderLink.remove();
      }
      if (seperator) {
        seperator.remove();
      }
    }
  }, [productCode, cal, lang]);

  const openModel = useCallback(() => {
    setModal(true);
    if (cal?.data?.data?.measuredSize) {
      if (!cal?.isLoaded) {
        getMeasurements();
      }
      setStep(ScreenTypeDto.Summary);
      saizEvents(ScreenTypeDto.Summary);
      setReturnToScreen(ScreenTypeDto.Summary);
    } else {
      setStep(ScreenTypeDto.ScreenOne);
      saizEvents(ScreenTypeDto.ScreenOne);
      setReturnToScreen(ScreenTypeDto.ScreenOne);
    }
  }, [lang]);

  const updateContent = useCallback(() => {
    const text =
      !cal?.isLoaded || cal?.data?.noSize
        ? t("question")
        : t("answer") + " " + cal?.data?.data?.measuredSize;
    const target = document.querySelector(
      "button[data-open-guide='fitGuidePicker']"
    );
    const targetSeperator = document.querySelector(
      "div.jwsdw-fitting-line.jws-displayInlineBlock"
    );
    const parentTarget = document.querySelector(
      "div.jws-margin-top-2.jws-margin-medium-top-0"
    );
    if (parentTarget) {
      const recommenderLink = document.getElementById("jwsaizlink");
      const linkElement = document.createElement("a");
      linkElement.href = "#";
      linkElement.innerHTML = text;
      linkElement.classList.add(
        "jwsdw-pdp-sizeGuideLink",
        "jws-copy1NanoTitle",
        "jws-colorBlack",
        "jws-textlink",
        "jwsdw-near-fitting"
      );
      linkElement.style.marginLeft = "auto";
      linkElement.id = "jwsaizlink";
      linkElement.addEventListener("click", (event) => {
        event.preventDefault();
        openModel();
      });
      const seperator = document.createElement("div");
      seperator.classList.add("jwsdw-fitting-line", "jws-displayInlineBlock");
      seperator.id = "jwsaizseperator";
      if (!recommenderLink) {
        parentTarget?.insertAdjacentElement("afterbegin", seperator);
        parentTarget?.insertAdjacentElement("afterbegin", linkElement);

        if (!target?.nextElementSibling) {
          target?.parentNode?.insertBefore(
            target,
            target.previousElementSibling
          );
          target?.parentNode?.insertBefore(
            target,
            target.previousElementSibling
          );
          targetSeperator?.parentNode?.insertBefore(
            targetSeperator,
            targetSeperator.previousElementSibling
          );
        }
      }
      updateLink();
    }
  }, [product, cal, lang]);

  const updateLink = useCallback(() => {
    // Update the text of the link element with the new size
    const linkElement = document.getElementById("jwsaizlink");
    const text =
      !cal?.isLoaded || cal?.data?.noSize
        ? t("question")
        : t("answer") + " " + cal?.data?.data?.measuredSize;
    if (linkElement) {
      linkElement.innerHTML = text;
      console.log("link updated", lang, t("question"), text);
    }
    const sizeButtons = document.querySelectorAll(".jwsdw-product-swatch");
    // Find the button matching the default size
    sizeButtons.forEach((btn: any) => {
      const span = btn.querySelector(".swatch-name");
      if (
        span &&
        span.textContent?.trim()?.toLowerCase() ===
          cal?.data?.data?.measuredSize?.toLowerCase()
      ) {
        console.log("Found!");
        btn.click(); // Simulate a click event on the button
      }
    });
  }, [cal, lang]);
  return <></>;
};
